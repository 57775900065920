/* -----------------------------------------------

Table of Content

	1. Body and Core Css
     - 1.1 Typography
     - 1.2 Button Style
     - 1.3 Form Style
     - 1.4 Card Style
     - 1.5 Nav Bar
     - 1.6 User Nav Bar
     - 1.7 Action Value

  2. Home Page
     - 2.1 Banner Section
     - 2.2 Categories Section
     - 2.3 Other Section
     - 2.4 Advantages Section

  4. User Page
     - 4.1 Side Bar
     - 4.2 Cabinet Page
     - 4.3 Refill Page
     - 4.4 History Page

----------------------------------- */

/* -----------------------------------
		    1. Body and Core Css
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font: {
    size: 14px;
    weight: 600;
    family: $font-main;
  }
  line-height: 1.3;
  text-align: center;
  color: $color-grey;
  background: $background-color;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  max-width: 1350px;
  margin: auto;
  padding: 50px 2rem;
}

.page-loader-wrapper {
  left: 0;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .ant-spin {
    top: 0;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: $element-color;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: $color-main;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-main;
}

/* 1.1 Typography */
h1 {
  font-size: 2.5rem;
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;
  margin-bottom: 0;
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 0;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 0;
}

h6 {
  font-size: 1rem;
  margin-bottom: 0;
}

a {
  color: $color-white;
  outline: none;
  text-decoration: none;

  &:hover {
    color: $color-main;
    outline: none;
    text-decoration: none;
  }
}

p {
  font: {
    size: 15px;
    weight: 600;
  }
  line-height: 1.7;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

.title-section {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid $element-color;

  h2 {
    color: $color-grey;
    text-transform: uppercase;
    font: {
      weight: 600;
    }
    text-align: left;
    background: $text-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid $element-color;
  }
}

.title-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 30px;
  border: 1px solid $border-color;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $background-grey-200;
    pointer-events: none;
  }

  &:before {
    top: -3px;
    left: 6px;
  }

  &:after {
    top: -3px;
    right: 6px;
  }

  h4 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }

    b {
      text-transform: uppercase;
      font: {
        weight: 500;
        family: "Plus Jakarta Sans", sans-serif;
      }
      background: $text-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $background-grey-200;
    }

    &:before {
      bottom: 26px;
      left: 6px;
    }

    &:after {
      bottom: 26px;
      right: 6px;
    }
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .price-value {
      font: {
        size: 17px;
        weight: 500;
      }
      text-transform: uppercase;
      font-family: "Plus Jakarta Sans", sans-serif;
      background: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      s {
        font-weight: 300;
        -webkit-text-fill-color: #5c5c5c;
        text-decoration: line-through !important;
      }

      i {
        font-style: normal;
      }
    }
  }
}

.title-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 30px;
  border: 1px solid $border-color;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $background-grey-200;
    pointer-events: none;
  }

  &:before {
    top: -3px;
    left: 6px;
  }

  &:after {
    top: -3px;
    right: 6px;
  }

  h4 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }

    b {
      text-transform: uppercase;
      font: {
        weight: 500;
        family: "Plus Jakarta Sans", sans-serif;
      }
      background: $text-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  s {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $background-grey-200;
    }

    &:before {
      bottom: 26px;
      left: 6px;
    }

    &:after {
      bottom: 26px;
      right: 6px;
    }
  }
}

/* 1.2 Button Style */
.btn-persv {
  -webkit-perspective: 500px;
  perspective: 500px;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

/* 1.3 Form Style */
.custom-switch {
  display: flex;
  justify-content: flex-end;

  .ant-row {
    .ant-form-item-control {
      .ant-form-item-control-input {
        min-height: 40px;
        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
          gap: 15px;
          color: $color-grey;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }
  }
}

/* 1.4 Card Style */
.card-item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 60px;
  background: $element-color;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;

  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    mask-image: url(../../img/icon/icon-mask.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;

    span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      inset: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(90deg, $color-main-500, transparent);
      transition-duration: 0.2s;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      animation: spin 3s linear infinite;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47px;
      height: 47px;
      background-color: $background-color;
      mask-image: url(../../img/icon/icon-mask.svg);
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;

      img {
        max-width: 24px;
      }
    }
  }

  .info {
    color: $color-grey;
    text-align: left;
    overflow: hidden;
    flex-grow: 1;
    padding-right: 42px;

    h5 {
      font-size: 18px;
      white-space: nowrap;
      transition: all 0.3s;
    }
  }

  .price {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    left: 50%;
    bottom: -45px;
    color: $color-grey;
    border-radius: 5px;
    background: #222325;
    padding: 20px 10px 11px;
    transform: translateX(-50%);
    z-index: -1;

    .price-value {
      display: flex;
      gap: 6px;
      background: rgba(0, 195, 154, 0.1);
      padding: 0px 10px;
      border-radius: 5px;
      color: #00c39a;
      outline: 1px dashed rgba(0, 195, 154, 0.1) !important;
      outline-offset: 3px;

      s {
        text-decoration: line-through !important;
        text-decoration-color: #00c39a;
      }
    }
  }

  .preview {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: 20px;
    padding: 5px;
    border-radius: 5px;
    background: $background-color;
    transform: translateY(-50%);
    transition: all 0.3s;
    z-index: 1;

    svg {
      transition: all 0.3s;
    }

    &:hover {
      svg {
        opacity: 0.5;
      }
    }
  }

  &:hover {
    .info {
      h5 {
        color: $color-main;
      }
    }
  }

  &.main {
    &::after {
      content: "";
      position: absolute;
      width: 90px;
      height: 71px;
      top: 0;
      right: 0;
      border-radius: 5px;
      background: linear-gradient(90deg, transparent 0%, $element-color 30%);
    }
  }

  &.second {
    background: transparent;
    border-top: 1px dashed #302c2c;
    border-left: 1px dashed #302c2c;
    border-radius: 10px 10px 10px 0;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);

    h5 {
      font-size: 16px;
    }

    .icon {
      width: 40px;
      height: 40px;

      i {
        width: 37px;
        height: 37px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .price {
      left: calc(50% - 1px);
      padding: 19px 10px 10px;
      background: transparent;
      border-left: 1px dashed #302c2c;
      border-radius: 0 10px 10px 10px;
      z-index: 0;
    }
  }

  &.edit {
    margin-bottom: 95px;

    .price {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      bottom: -80px;
      padding: 30px 10px 11px;

      .price-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .price-value {
        outline: none !important;

        s {
          text-decoration: none !important;
        }

        &.discount {
          color: $color-grey;
          background: transparent;
        }
      }
    }
  }
}

.system-item-modal {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: $background-color;
  transition: none;

  h6 {
    transition: color 0.3s;
  }

  span {
    position: absolute;
    top: 50%;
    right: 15px;
    line-height: 0;
    transform: translateY(-50%);

    svg {
      g [fill] {
        transition: all 0.3s;
      }
    }
  }

  &:hover {
    h6 {
      color: $color-main;
    }

    span {
      svg {
        g [fill] {
          fill: $color-main;
        }
      }
    }
  }
}

/* 1.5 Nav Bar */
.nav-bar {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  top: 0;
  color: $color-grey;
  background: rgba(20, 18, 18, 0.9);
  z-index: 9;

  .logo {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font: {
        size: 25px;
        weight: 600;
        family: "Plus Jakarta Sans", sans-serif;
      }
      text-transform: uppercase;
      background: $text-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      max-width: 45px;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    gap: 20px;

    .dropdown-btn {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: $font-main;
      height: 40px;
      padding: 9px;
      border-radius: 5px;
      background: $element-color;
      border: none;

      &.ant-dropdown-open {
        .arrow {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }

      svg {
        margin-right: 7px;

        g [fill] {
          transition: all 0.3s;
        }
      }

      .arrow {
        position: relative;
        display: flex;
        padding: 3px;
        margin-left: 10px;
        border-radius: 50%;
        background: $background-color;

        svg {
          margin: 0;
          transition: all 0.3s;
        }
      }
    }

    .balance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      line-height: 1;
      font: {
        size: 13px;
        family: "Jura", sans-serif;
      }
      color: #7a7a7a;
      padding: 5px 15px;
      border-radius: 7px;
      outline: 1px dashed #222325 !important;
      outline-offset: 0px;

      s {
        font-size: 15px;
        color: #ababab;
        text-transform: uppercase;
        font-weight: 800;
        text-align: left;
        background: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.item-lang img {
  max-width: 15px;
  margin-right: 10px;
  border-radius: 50%;
}

/* 1.6 User Nav Bar */
.user-bar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 250px);
  top: 2rem;
  left: 200px;
  z-index: 1;

  .ant-input-search {
    max-width: 250px;

    .ant-input-wrapper {
      background: #202124;
      outline: 1px dashed #202124 !important;
      outline-offset: 3px;
      transition: all 0.3s;

      .ant-input-affix-wrapper {
        background: #202124;
        outline: none !important;
        box-shadow: none !important;
      }

      .ant-input {
        background: #202124;
      }

      &:hover {
        outline-color: $color-main-500 !important;
      }
    }

    .ant-input-search-button {
      padding: 10px;
      border-radius: 6px !important;
      outline: none !important;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .right-side {
    display: flex;
    gap: 15px;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 10px;
      background-color: #1b1b1b;

      svg {
        opacity: 0.6;
        transition: all 0.3s;
      }

      &:hover {
        svg {
          g {
            path {
              transition: all 0.3s;
              fill: $color-main;
            }
          }
        }
      }
    }

    .user-menu {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      border: none;
      background: transparent;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -5px;
        bottom: -5px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #202124;

        svg {
          transition: all 0.3s;
        }
      }

      &.ant-dropdown-open {
        span {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }
    }

    .lang {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: $font-main;
      height: 40px;
      padding: 9px;
      border-radius: 5px;
      background: #1b1b1b;
      border: none;

      &.ant-dropdown-open {
        .arrow {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }

      svg {
        margin-right: 7px;

        g [fill] {
          transition: all 0.3s;
        }
      }

      .arrow {
        position: relative;
        display: flex;
        padding: 3px;
        margin-left: 10px;
        border-radius: 50%;
        background: $background-color;

        svg {
          margin: 0;
          transition: all 0.3s;
        }
      }
    }

    .balance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      line-height: 1;
      font: {
        size: 13px;
        family: "Jura", sans-serif;
      }
      color: #7a7a7a;
      padding: 5px 15px;
      border-radius: 7px;
      outline: 1px dashed #222325 !important;
      outline-offset: 0px;

      s {
        font-size: 15px;
        color: #ababab;
        text-transform: uppercase;
        font-weight: 800;
        text-align: left;
        background: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .toggle-sidebar-btn {
    display: none;
    padding: 5px;
    transition: all 0.3s;

    &.active {
      left: 100px;
    }
  }
}

/* 1.7 Action Value */
.action-value {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  min-width: 100%;
  border-radius: 6px;
  margin-bottom: 30px;
  background: $background-color;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 3px;

  span {
    padding: 10px;
  }

  .ant-btn-dashed {
    height: 35px;
    border-radius: 0;
    border-left: 1px dashed $background-grey-200;
    background: $background-color;
    outline: none !important;

    &:hover {
      border-color: $background-grey-200 !important;
    }
  }
}

/* 1.8 Breadcrumb */
.breadcrumb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 15px;
  border-radius: 10px;
  margin: 0 0.5rem 40px;
  background-color: $background-color;

  .title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
  }

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    border-radius: 10px;
    background: linear-gradient(161deg, #282828 20%, transparent 65%);
    z-index: -1;
  }
}

/* -----------------------------------
		        2. Home Page
----------------------------------- */
.home {
  /* 2.1 Banner Section */
  .banner {
    position: relative;
    padding: 50px 0;

    h1 {
      position: relative;
      padding: 2px 25px 3px;
      font: {
        weight: 600;
        family: "Plus Jakarta Sans", sans-serif;
      }
      text-align: left;
      margin-bottom: 30px;
      text-transform: uppercase;
      background: $text-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border: 1px solid $border-color;

      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        pointer-events: none;
        background: $background-grey-200;
      }

      &:before {
        top: -3px;
        left: -3px;
      }

      &:after {
        top: -3px;
        right: -3px;
      }

      span {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;

        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $background-grey-200;
        }

        &:before {
          bottom: -3px;
          left: -3px;
        }

        &:after {
          bottom: -3px;
          right: -3px;
        }
      }
    }

    p {
      text-align: left;
    }
  }

  /* 2.2 Categories Section */
  .сategories {
    padding: 50px 0;

    p {
      text-align: left;
    }

    .platforms {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 2rem 0 1rem;

      .item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 1rem;
        background: $element-color;
        outline: 1px dashed $element-color !important;
        outline-offset: 3px;
        transition: all 0.3s;

        img {
          width: 100px;
          height: 30px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .image {
      position: relative;

      img {
        max-width: 80%;
      }

      .icon-element {
        position: absolute;
        left: 110px;
        bottom: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        mask-image: url(../../img/icon/icon-mask.svg);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        animation: moveUpDown 3s linear infinite;

        span {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          inset: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            90deg,
            $color-main-500,
            transparent
          );
          transition-duration: 0.2s;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation: spin 3s linear infinite;
        }

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $element-color;
          mask-image: url(../../img/icon/icon-mask.svg);
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: contain;
        }

        &:nth-child(3) {
          top: 55px;
          right: 100px;
          left: unset;
          bottom: unset;
        }
      }
    }
  }

  /* 2.3 Other Section */
  .other-documents {
    padding: 50px 0;

    .item {
      position: relative;
      padding: 20px;
      border-radius: 20px;
      background-color: #1b1b1b;

      .head {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 19px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px dashed #323232;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-radius: 5px;
          background: #151515;
        }
      }

      span {
        &.ant-typography {
          text-align: left;
        }
      }

      .images {
        width: 250px;
      }

      &::after {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        border-radius: 20px;
        background: linear-gradient(161deg, #282828 20%, transparent 65%);
        z-index: -1;
      }

      .icon-element {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        mask-image: url(../../img/icon/icon-mask.svg);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;

        span {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          inset: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            90deg,
            $color-main-500,
            transparent
          );
          transition-duration: 0.2s;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation: spin 3s linear infinite;
        }

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $background-color;
          mask-image: url(../../img/icon/icon-mask.svg);
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: contain;

          img {
            max-width: 24px;
          }
        }
      }

      &.soon {
        .coming {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;

          .ant-typography {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            font: {
              size: 24px;
              style: italic;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 99%;
          top: 0;
          left: 0;
          border-radius: 20px;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          z-index: 2;
        }
      }
    }
  }

  /* 2.4 Advantages Section */
  .advantages {
    position: relative;
    padding: 50px 0;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 10px;
      border-radius: 5px;
      background: $element-color;
      transition: all 0.3s;
      margin: 15px 10px;
      outline: 1px dashed $background-grey-200 !important;
      outline-offset: 3px;

      h4 {
        font-weight: 700;
        text-transform: uppercase;
        background: $text-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-element {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      mask-image: url(../../img/icon/icon-mask.svg);
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;

      span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        inset: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, $color-main-500, transparent);
        transition-duration: 0.2s;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation: spin 3s linear infinite;
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-color;
        mask-image: url(../../img/icon/icon-mask.svg);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
      }
    }

    img {
      max-width: 70%;
    }
  }

  /* 2.5 How Work */
  .how-work {
    position: relative;
    padding: 50px 0;

    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      img {
        max-width: 90%;
      }

      h4 {
        font-weight: 700;
        text-transform: uppercase;
        background: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
      }
    }
  }
}

/* -----------------------------------
		      3. User Page
----------------------------------- */
.user {
  position: relative;
  display: flex;
  max-width: 100%;
  min-height: 100vh;
  padding: 0;

  /* 4.1 Side Bar */
  .side-bar {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 150px;
    padding: 0 2rem;

    .logo {
      margin-top: 30px;
      max-width: 50px;
    }

    .side-bar-menu {
      a {
        padding-left: 16px;
      }
    }
  }

  .box {
    position: relative;
    padding: 20px;
    border-radius: 20px;
    background-color: #1b1b1b;

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      border-radius: 20px;
      background: linear-gradient(161deg, #282828 20%, transparent 65%);
      z-index: -1;
    }
  }

  /* 4.2 Cabinet Page */
  .layout {
    position: relative;
    margin-top: 110px;
    width: calc(100% - 175px);
    padding-left: 2rem;
    padding-bottom: 30px;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% + 110px);
      top: -110px;
      left: 0;
      background-color: #202321;
    }
  }

  .cabinet {
    .banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .user-banner {
        img {
          width: 200px;
          opacity: 0.4;
        }
      }

      .info {
        h3 {
          text-align: left;
          margin-bottom: 30px;
        }

        .balance {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          .amount {
            a {
              position: absolute;
              right: 5px;
              bottom: 5px;
              padding: 5px 8px;
              outline: none !important;
            }
          }
        }

        .purchase {
          position: relative;
          margin-top: 20px;

          .ant-typography {
            display: flex;
            align-items: center;
            justify-content: space-between;

            s {
              display: flex;
              background: rgba(0, 195, 154, 0.1);
              padding: 0px 10px;
              border-radius: 10px;
              color: #00c39a;
              outline: 1px dashed rgba(0, 195, 154, 0.1) !important;
              outline-offset: 3px;
            }
          }
        }
      }
    }

    .top {
      .items {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 9px;
        margin-top: 30px;
        z-index: 1;

        .card-item {
          .price {
            padding: 20px 10px 5px;

            .award {
              position: relative;
              top: -2px;
              left: 5px;

              img {
                width: 40px;
              }
            }

            span {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }

      &.soon {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 99%;
          top: 0;
          left: 0;
          border-radius: 20px;
          -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
          background: transparent;
          z-index: 2;
        }

        .coming {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;

          .ant-typography {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            font: {
              size: 24px;
              style: italic;
            }
          }
        }
      }
    }

    .propose {
      height: 100%;

      .add-document {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 60px;
        height: 100%;

        .icon {
          position: relative;
          transition: all 0.3s;

          .circle {
            position: absolute;
            width: 120px;
            height: 120px;
            top: -25px;
            left: -25px;
            animation: spin 7s infinite linear;

            .dotted {
              stroke-dasharray: 0.1, 12.5;
              stroke-linecap: round;
            }

            circle {
              fill: transparent;
              stroke: $color-main;
              stroke-width: 3;
            }
          }

          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 140px;
            height: 140px;
            top: calc(50% - 2px);
            left: 50%;
            border-radius: 50%;
            filter: blur(3px);
            opacity: 0.7;
            border: 1px solid $color-main;
            transform: translate(-50%, -50%);
          }

          &::before {
            width: 190px;
            height: 190px;
            filter: blur(3px);
            opacity: 0.2;
            border: 1px solid $color-main;
          }
        }

        .ant-btn-dashed {
          color: #fff;
          font-size: 16px;
          background: #2d2e30;
          outline: 1px dashed #373737 !important;
          outline-offset: 3px;
        }

        &:hover {
          .icon {
            opacity: 0.5;
            transform: scale(1.1);

            svg {
              g path {
                fill: $color-main;
              }
            }
          }

          .ant-btn-dashed {
            color: #00c39a !important;
            opacity: 1;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 50%;
        background-repeat: no-repeat;
        opacity: 0.7;
        background-image: url(../../img/grid.svg);
        transform: translateX(-50%);
      }

      &.soon {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 99%;
          top: 0;
          left: 0;
          border-radius: 20px;
          -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
          background: transparent;
          z-index: 2;
        }

        .coming {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;

          .ant-typography {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            font: {
              size: 24px;
              style: italic;
            }
          }
        }
      }
    }

    .operations {
      height: 100%;

      .items {
        height: calc(100vh - 250px);
        overflow: hidden auto;
        margin-top: 30px;
        padding-right: 10px;
      }
    }
  }

  /* 4.3 Refill Page */
  .refill {
    .refill-row {
      height: 100%;
    }

    .refill-history {
      display: flex;
      flex-direction: column;

      .items {
        min-height: calc(100% - 68px);
        position: relative;
        max-height: 421px;
        padding-right: 5px;
        overflow: hidden scroll;

        .card-item {
          &.second {
            cursor: auto;
            margin-bottom: 20px;
            border-radius: 10px;

            .info {
              display: flex;
              text-align: left;
              justify-content: space-between;
              gap: 5px;
              padding-right: 10px;

              .info__block {
                display: flex;
                flex-direction: column;
                gap: 7px;

                span {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  color: #747474;

                  s {
                    text-transform: uppercase;
                    color: #ababab;
                  }

                  .status {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      &:first-child {
                        color: #747474;
                      }
                    }
                  }
                }

                &:last-child {
                  span {
                    justify-content: flex-end;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* 4.4 History Page */
  .history {
    .box {
      .items {
        height: calc(100vh - 240px);
        overflow: hidden auto;
        margin-top: 30px;
        padding-right: 10px;

        .card-item {
          background: transparent;
          border-top: 1px dashed #302c2c;
          border-left: 1px dashed #302c2c;
          box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
          border-radius: 10px 10px 10px 0;

          .icon {
            i {
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }

          .price {
            left: calc(50% - 1px);
            padding: 19px 10px 10px;
            border-radius: 0 10px 10px 10px;
            border-left: 1px dashed #302c2c;
            background: transparent;
            z-index: 0;
          }
        }
      }
    }
  }

  /* Admin */
  .admin {
    &.main {
      img {
        max-width: 50%;
      }
    }

    &.docs {
      .title-section {
        padding-bottom: 15px;
        margin-bottom: 15px;

        h2 {
          font-size: 28px;
        }
      }
    }

    &.users-list {
      position: relative;

      .ant-input-search {
        max-width: 350px;

        .ant-input-wrapper {
          background: #202124;
          outline: 1px dashed #202124 !important;
          outline-offset: 3px;
          transition: all 0.3s;

          .ant-input-affix-wrapper {
            background: #202124;
            outline: none !important;
            box-shadow: none !important;
          }

          .ant-input {
            background: #202124;
          }

          &:hover {
            outline-color: $color-main-500 !important;
          }
        }

        .ant-input-search-button {
          border-radius: 6px !important;
          outline: none !important;
          background-color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    &.edit-user {
      position: relative;

      .edit-form {
        .custom-switch {
          margin: 15px 0 0;
          justify-content: center;
        }
      }

      .boost {
        .balance {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          padding: 0 0.5rem;
          margin-bottom: 30px;

          span {
            font-size: 15px;
            text-align: left;
            padding: 5px 10px;
            min-width: 100%;
            border-radius: 6px;
            background: #151515;
            outline: 1px dashed #383b40 !important;
            outline-offset: 3px;
          }
        }
      }
    }
  }
}

.spin-loader-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* -----------------------------------
		      4. All Documents
----------------------------------- */
.all-docs {
  padding: 50px 0;

  .ant-spin {
    width: 100%;
    padding: 10px;
  }

  .ant-input-search {
    max-width: 350px;

    .ant-input-wrapper {
      background: #202124;
      outline: 1px dashed #202124 !important;
      outline-offset: 3px;
      transition: all 0.3s;

      .ant-input-affix-wrapper {
        background: #202124;
        outline: none !important;
        box-shadow: none !important;
      }

      .ant-input {
        background: #202124;
      }

      &:hover {
        outline-color: $color-main-500 !important;
      }
    }

    .ant-input-search-button {
      padding: 10px;
      border-radius: 6px !important;
      outline: none !important;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-empty {
    position: relative;
    padding: 40px 20px;
    border-radius: 20px;
    background-color: #1b1b1b;
    margin: 0;
  }
}

/* 5. Modal */
.modal-purchases {
  .ant-modal-body {
    height: calc(100vh - 148px);
    display: flex;
    flex-direction: column;
    line-height: 1.3;
  }

  .gen-page {
    padding: 0;
    height: 100%;
    overflow-y: auto;
  }
}

.modal-qrcode {
  .spin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;

    .qr-code {
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      svg {
        width: 170px;
        height: auto;
        border-radius: 10px;
        outline: 1px dashed $background-grey-200 !important;
        outline-offset: 7px;
      }
    }

    .result {
      width: 100%;

      p {
        span {
          color: $color-main;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .ant-alert {
        margin: 30px 0 0;
      }
    }
  }
}

.modal-editdoc {
  .custom-switch {
    justify-content: center;
  }

  .sale {
    position: relative;

    .ant-checkbox-wrapper {
      position: absolute;
      top: 2px;
      right: 8px;
      font-size: 12px;

      .ant-checkbox {
        .ant-checkbox-inner {
          outline: none !important;
        }
      }
    }
  }
}

.modal-signup {
  .ant-modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;

    img {
      max-width: 60%;
    }

    p {
      text-align: center;
    }
  }
}

.delete-purchase-msg {
  strong {
    color: #00c39a;
    font-weight: 700;
  }
}
