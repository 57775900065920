/* xxxl, large: 1500px */
$breakpoint-xxxl: 1500px;
/* xxl, large: 1350px */
$breakpoint-xxl: 1350px;
/* xl, large: 1200px */
$breakpoint-xl: 1200px;
/* lg, large: 992px */
$breakpoint-lg: 992px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media screen and (max-width: $breakpoint-xxxl) {
  .user {
    .refill {
      .refill-history {
        .items {
          .card-item.second {
            .icon {
              display: none;
            }
          }
        }
      }
    }

    .admin {
      &.docs {
        .card-item {
          flex-basis: calc(50% - 2 * 5px);
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xxl) {
  section {
    max-width: 1200px;
  }

  .user-bar {
    width: calc(100% - 170px);
    left: 135px;
  }

  .user {
    .side-bar {
      width: 100px;
      padding: 0 1rem;
    }

    .layout {
      width: calc(100% - 115px);
      padding-left: 1rem;
    }

    .cabinet {
      .top {
        .items {
          .card-item {
            .icon {
              width: 40px;
              height: 40px;

              i {
                width: 37px;
                height: 37px;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            .info {
              h5 {
                font-size: 16px;
              }
            }

            .price {
              .award {
                left: 1px;

                img {
                  width: 35px;
                }
              }
            }

            &.main {
              &::after {
                height: 59px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xl) {
  section {
    max-width: 992px;
  }

  .icon-element {
    &.w-\[55px\] {
      width: 45px;
    }

    &.h-\[55px\] {
      height: 45px;
    }

    i {
      &.w-\[52px\] {
        width: 42px;
      }

      &.h-\[52px\] {
        height: 42px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .home {
    .other-documents {
      .item {
        margin-bottom: 30px;
      }
    }

    .advantages {
      .item {
        margin: 10px 5px;

        h4 {
          font-size: 1.2rem;
        }
      }
    }
  }

  .card-item {
    .icon {
      width: 40px;
      height: 40px;

      i {
        width: 37px;
        height: 37px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .info {
      h5 {
        font-size: 16px;
      }
    }
  }

  .custom-row {
    .result {
      top: -220px;
      left: -10%;
      transform: scale(0.28) translate(-100%, -100%);
    }
  }

  .gen-page {
    .box {
      width: calc(100% - 420px);
    }
  }

  .user {
    .side-bar {
      width: 100px;
      padding: 0 1rem;
    }

    .cabinet {
      .operations {
        height: auto;
        margin: 30px 0.5rem;
      }
    }

    .refill {
      .refill-history {
        .items {
          .card-item.second {
            .icon {
              display: flex;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  section {
    max-width: 768px;
  }

  .ant-breadcrumb {
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 60%;
      top: 50%;
      right: 0;
      background: linear-gradient(90deg, transparent 0%, #151515 60%);
      transform: translateY(-50%);
    }
  }

  .advantages {
    img {
      max-width: 60%;
    }
  }

  .custom-row {
    .result {
      top: -9999px;
      left: -9999px;
      z-index: -9999;
    }
  }

  .gen-page {
    .box {
      width: 100%;
    }

    .custom-row {
      .result {
        opacity: 0;
      }
    }
  }

  .ant-transfer {
    flex-direction: column;

    .ant-transfer-list {
      width: 100% !important;
    }

    .ant-transfer-operation {
      flex-direction: row;
      margin: 15px 0px;

      .ant-btn:first-child {
        margin: 0;
        transform: rotate(90deg);
      }

      .ant-btn:last-child {
        margin: 0;
        transform: rotate(90deg);
      }
    }
  }

  .user {
    .cabinet {
      .propose {
        height: auto;
        margin: 30px 0 0;

        .add-document {
          min-height: 300px;
          margin-top: 30px;
        }

        &::before {
          background-position: center;
        }
      }
    }

    .admin {
      &.docs {
        .card-item {
          flex-basis: calc(100% - 2 * 5px);
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  section {
    max-width: 575px;
  }

  .nav-bar {
    height: 130px;
    flex-direction: column;
    padding: 15px;

    .logo {
      margin-right: auto;
    }

    .right-side {
      width: 100%;
      justify-content: space-between;

      .user-menu-btn {
        position: absolute;
        top: 25px;
        right: 15px;
      }
    }
  }

  .banner {
    .ant-row {
      .ant-col {
        &:nth-child(1) {
          order: 2;
          margin-top: 50px;
        }
      }
    }

    p {
      text-align: center;
    }

    img {
      max-width: 60%;
    }
  }

  .advantages {
    img {
      max-width: 90%;
    }
  }

  .user-bar {
    width: 100%;
    left: 0;
    padding: 0 20px;

    .ant-input-search {
      display: none;
    }

    .toggle-sidebar-btn {
      display: flex;
      background: #12332c;
      z-index: 2;
    }

    .right-side {
      .icon {
        &.item-history,
        &.item-document {
          display: none;
        }
      }
    }
  }

  .user {
    .side-bar {
      position: fixed;
      left: -100px;
      height: 100vh;
      background: #151515;
      border-right: 1px solid #202321;
      box-shadow: 15px 0px 50px 0px rgba(0, 0, 0, 0.5);
      transition: all 0.3s;
      z-index: 9;

      &.active {
        left: 0;
      }
    }

    .layout {
      width: 100%;
      padding-left: 0;
    }

    .admin {
      &.docs {
        .card-item {
          .card-item__block {
            p {
              span {
                max-width: none;
              }
            }
          }
        }
      }

      &.users-list {
        .title-head {
          flex-direction: column;
          gap: 15px;
        }

        .ant-input-search {
          max-width: 100%;
        }
      }
    }
  }

  .modal-qrcode {
    .content {
      flex-direction: column;
    }
  }

  .action-value {
    font-size: 14px;

    .ant-btn-dashed {
      padding: 7px;
    }
  }

  .ant-modal {
    .ant-modal-content {
      padding: 20px 10px;
    }
  }

  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          justify-content: center;
        }
      }
    }
  }

  .all-docs {
    .title-head {
      flex-direction: column;
      gap: 15px;
    }

    .ant-input-search {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  h2 {
    font-size: 24px;
  }

  .title-page {
    gap: 10px;
    flex-direction: column;

    h4 {
      flex-direction: column;
      b {
        font-size: 20px;
      }
    }

    .price {
      align-items: center;
    }
  }

  .text-center-sm {
    text-align: center;
  }

  .custom-switch {
    .ant-form-item-label {
      text-align: center;
    }
  }

  .ant-btn-default {
    padding: 15px 20px;
  }

  .advantages {
    img {
      max-width: 100%;
    }
  }

  .banner {
    img {
      max-width: 100%;
    }
  }

  .custom-switch {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gen-page {
    .box {
      .subtitle {
        justify-content: center;
      }

      .system_title {
        align-items: center;
      }
    }
  }

  .custom-row {
    .form {
      &.box {
        width: calc(100% - 20px);
        margin: auto;
      }
    }
  }

  h4.ant-typography {
    font-size: 18px;
  }

  .user {
    .box {
      padding: 20px 10px;
    }

    .cabinet {
      .banner {
        flex-direction: column;

        .info {
          h3 {
            font-size: 24px;
          }
        }

        .user-banner {
          img {
            width: 110px;
          }
        }
      }
    }

    .refill {
      .refill-history {
        .items {
          .card-item.second {
            .icon {
              display: none;
            }
          }
        }
      }
    }

    .admin {
      &.docs {
        .card-item {
          .card-item__block {
            flex-direction: column;
          }
        }
      }
    }
  }
}
