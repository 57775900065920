$font-main: "Jura", sans-serif;

/* PF Din Text Universal */
@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-Hairline.eot");
  src: local("PF Din Text Universal Hairline"),
    local("PFDinTextUniversal-Hairline"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Hairline.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Hairline.woff2")
      format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Hairline.woff") format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Hairline.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-Medium.eot");
  src: local("PF Din Text Universal Medium"), local("PFDinTextUniversal-Medium"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Medium.woff2") format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Medium.woff") format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-ExtraThin.eot");
  src: local("PF Din Text Universal ExtraThin"),
    local("PFDinTextUniversal-ExtraThin"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraThin.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraThin.woff2")
      format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraThin.woff")
      format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraThin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-Light.eot");
  src: local("PF Din Text Universal Light"), local("PFDinTextUniversal-Light"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Light.woff2") format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Light.woff") format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal.eot");
  src: local("PF Din Text Universal"), local("PFDinTextUniversal"),
    url("../../fonts/PFDinText/PFDinTextUniversal.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal.woff2") format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal.woff") format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-ExtraBlack.eot");
  src: local("PF Din Text Universal ExtraBlack"),
    local("PFDinTextUniversal-ExtraBlack"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraBlack.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraBlack.woff2")
      format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraBlack.woff")
      format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-ExtraBlack.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-Thin.eot");
  src: local("PF Din Text Universal Thin"), local("PFDinTextUniversal-Thin"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Thin.woff2") format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Thin.woff") format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PF Din Text Universal";
  src: url("../../fonts/PFDinText/PFDinTextUniversal-Bold.eot");
  src: local("PF Din Text Universal Bold"), local("PFDinTextUniversal-Bold"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Bold.woff2") format("woff2"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Bold.woff") format("woff"),
    url("../../fonts/PFDinText/PFDinTextUniversal-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* SF UI */
@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-HeavyItalic.eot");
  src: local("SF UI Text Heavy Italic"), local("SFUIText-HeavyItalic"),
    url("../../fonts/SF/SFUIText-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-HeavyItalic.woff") format("woff"),
    url("../../fonts/SF/SFUIText-HeavyItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-LightItalic.eot");
  src: local("SF UI Text Light Italic"), local("SFUIText-LightItalic"),
    url("../../fonts/SF/SFUIText-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-LightItalic.woff") format("woff"),
    url("../../fonts/SF/SFUIText-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-MediumItalic.eot");
  src: local("SF UI Text Medium Italic"), local("SFUIText-MediumItalic"),
    url("../../fonts/SF/SFUIText-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-MediumItalic.woff") format("woff"),
    url("../../fonts/SF/SFUIText-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Medium.eot");
  src: local("SF UI Display Medium"), local("SFUIDisplay-Medium"),
    url("../../fonts/SF/SFUIDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Medium.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Regular.eot");
  src: local("SF UI Display Regular"), local("SFUIDisplay-Regular"),
    url("../../fonts/SF/SFUIDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Regular.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Light.eot");
  src: local("SF UI Display Light"), local("SFUIDisplay-Light"),
    url("../../fonts/SF/SFUIDisplay-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Light.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Thin.eot");
  src: local("SF UI Display Thin"), local("SFUIDisplay-Thin"),
    url("../../fonts/SF/SFUIDisplay-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Thin.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Ultralight.eot");
  src: local("SF UI Display Ultralight"), local("SFUIDisplay-Ultralight"),
    url("../../fonts/SF/SFUIDisplay-Ultralight.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Ultralight.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Ultralight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Semibold.eot");
  src: local("SF UI Text Semibold"), local("SFUIText-Semibold"),
    url("../../fonts/SF/SFUIText-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Semibold.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Semibold.eot");
  src: local("SF UI Display Semibold"), local("SFUIDisplay-Semibold"),
    url("../../fonts/SF/SFUIDisplay-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Semibold.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Regular.eot");
  src: local("SF UI Text Regular"), local("SFUIText-Regular"),
    url("../../fonts/SF/SFUIText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Regular.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Black.eot");
  src: local("SF UI Display Black"), local("SFUIDisplay-Black"),
    url("../../fonts/SF/SFUIDisplay-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Black.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Black.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-BoldItalic.eot");
  src: local("SF UI Text Bold Italic"), local("SFUIText-BoldItalic"),
    url("../../fonts/SF/SFUIText-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-BoldItalic.woff") format("woff"),
    url("../../fonts/SF/SFUIText-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Bold.eot");
  src: local("SF UI Text Bold"), local("SFUIText-Bold"),
    url("../../fonts/SF/SFUIText-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Bold.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Heavy.eot");
  src: local("SF UI Display Heavy"), local("SFUIDisplay-Heavy"),
    url("../../fonts/SF/SFUIDisplay-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Heavy.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Light.eot");
  src: local("SF UI Text Light"), local("SFUIText-Light"),
    url("../../fonts/SF/SFUIText-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Light.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Heavy.eot");
  src: local("SF UI Text Heavy"), local("SFUIText-Heavy"),
    url("../../fonts/SF/SFUIText-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Heavy.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Medium.eot");
  src: local("SF UI Text Medium"), local("SFUIText-Medium"),
    url("../../fonts/SF/SFUIText-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Medium.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../../fonts/SF/SFUIDisplay-Bold.eot");
  src: local("SF UI Display Bold"), local("SFUIDisplay-Bold"),
    url("../../fonts/SF/SFUIDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIDisplay-Bold.woff") format("woff"),
    url("../../fonts/SF/SFUIDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-Italic.eot");
  src: local("SF UI Text Italic"), local("SFUIText-Italic"),
    url("../../fonts/SF/SFUIText-Italic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-Italic.woff") format("woff"),
    url("../../fonts/SF/SFUIText-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../../fonts/SF/SFUIText-SemiboldItalic.eot");
  src: local("SF UI Text Semibold Italic"), local("SFUIText-SemiboldItalic"),
    url("../../fonts/SF/SFUIText-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/SF/SFUIText-SemiboldItalic.woff") format("woff"),
    url("../../fonts/SF/SFUIText-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* IBM Plex Sans */
@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-Thin-100.woff2") format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-Thin-100.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-ExtraLight-200.woff2")
      format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-ExtraLight-200.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-Light-300.woff2")
      format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-Light-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-Regular-400.woff2")
      format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-Regular-400.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-Medium-500.woff2")
      format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-Medium-500.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-SemiBold-600.woff2")
      format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-SemiBold-600.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../../fonts/IBMPlexSans/IBMPlexSans-Bold-700.woff2") format("woff2"),
    url("../../fonts/IBMPlexSans/IBMPlexSans-Bold-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Inter */
@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-100.woff") format("woff"),
    url("../../fonts/Inter/Inter-100.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-200.woff") format("woff"),
    url("../../fonts/Inter/Inter-200.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-300.woff") format("woff"),
    url("../../fonts/Inter/Inter-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-400.woff") format("woff"),
    url("../../fonts/Inter/Inter-400.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-500.woff") format("woff"),
    url("../../fonts/Inter/Inter-500.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-600.woff") format("woff"),
    url("../../fonts/Inter/Inter-600.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-700.woff") format("woff"),
    url("../../fonts/Inter/Inter-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-800.woff") format("woff"),
    url("../../fonts/Inter/Inter-800.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-900.woff") format("woff"),
    url("../../fonts/Inter/Inter-900.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Google Fonts */
@font-face {
  font-family: "Google Font";
  src: url("../../fonts/GoogleFont/Google-Font.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Google Font 2";
  src: url("../../fonts/GoogleFont/Google-Font-2.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Google Font 3";
  src: url("../../fonts/GoogleFont/Google-Font-3.woff2") format("woff2");
  font-style: normal;
}

/* Outfit */
@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-Thin-100.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-ExtraLight-200.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-Light-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-Regular-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-Medium-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-SemiBold-600.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-Bold-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-ExtraBold-800.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Outfit";
  src: url("../../fonts/Outfit/Outfit-Black-900.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

/* SBSansDisplay */
@font-face {
  font-family: "SBSansDisplay";
  src: url("../../fonts/SBSansDisplay/SBSansDisplay-Light.woff2")
    format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SBSansDisplay";
  src: url("../../fonts/SBSansDisplay/SBSansDisplay-Regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SBSansDisplay";
  src: url("../../fonts/SBSansDisplay/SBSansDisplay-SemiBold.woff2")
    format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SBSansDisplay";
  src: url("../../fonts/SBSansDisplay/SBSansDisplay-Bold.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

/* SBSansText */
@font-face {
  font-family: "SBSansText";
  src: url("../../fonts/SBSansText/sbsanstext-medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

/* Yantramanav */
@font-face {
  font-family: "Yantramanav";
  src: local("Yantramanav Medium"), local("Yantramanav-Medium"),
    url("../../fonts/Yantramanav/Yantramanavmedium.woff2") format("woff2"),
    url("../../fonts/Yantramanav/Yantramanavmedium.woff") format("woff"),
    url("../../fonts/Yantramanav/Yantramanavmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Yantramanav";
  src: local("Yantramanav Thin"), local("Yantramanav-Thin"),
    url("../../fonts/Yantramanav/Yantramanavthin.woff2") format("woff2"),
    url("../../fonts/Yantramanav/Yantramanavthin.woff") format("woff"),
    url("../../fonts/Yantramanav/Yantramanavthin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Yantramanav";
  src: local("Yantramanav Light"), local("Yantramanav-Light"),
    url("../../fonts/Yantramanav/Yantramanavlight.woff2") format("woff2"),
    url("../../fonts/Yantramanav/Yantramanavlight.woff") format("woff"),
    url("../../fonts/Yantramanav/Yantramanavlight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Yantramanav";
  src: local("Yantramanav Regular"), local("Yantramanav-Regular"),
    url("../../fonts/Yantramanav/Yantramanavregular.woff2") format("woff2"),
    url("../../fonts/Yantramanav/Yantramanavregular.woff") format("woff"),
    url("../../fonts/Yantramanav/Yantramanavregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Yantramanav";
  src: local("Yantramanav Black"), local("Yantramanav-Black"),
    url("../../fonts/Yantramanav/Yantramanavblack.woff2") format("woff2"),
    url("../../fonts/Yantramanav/Yantramanavblack.woff") format("woff"),
    url("../../fonts/Yantramanav/Yantramanavblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Yantramanav";
  src: local("Yantramanav Bold Bold"), local("Yantramanav-Bold"),
    url("../../fonts/Yantramanav/Yantramanavboldbold.woff2") format("woff2"),
    url("../../fonts/Yantramanav/Yantramanavboldbold.woff") format("woff"),
    url("../../fonts/Yantramanav/Yantramanavboldbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
