$color-white: #fff;
$color-black: #000;
$color-yellow: #f1c026;
$color-grey: #ababab;
$color-red: #d94c48;
$color-main: #00c39a;
$element-color: #222325;
$background-color: #151515;
$background-grey-200: #383b40;
$second-element-color: #1c1d1e;

$color-main-100: rgba(0, 195, 154, 0.1);
$color-main-200: rgba(0, 195, 154, 0.2);
$color-main-500: rgba(0, 195, 154, 0.5);
$disabled-color: rgba(255, 255, 255, 0.5);
$color-outline-input: rgba(0, 195, 154, 0.6);
$disabled-background: rgba(255, 255, 255, 0.1);

$error-border: rgba(217, 76, 72, 0.5);
$border-color: rgba(255, 255, 255, 0.1);

$shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
$text-gradient: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
