@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes flicker {
  0%,
  100% {
    background-color: $background-color;
  }

  50% {
    background-color: $color-main;
  }
}
