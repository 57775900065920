/* -----------------------------------------------

Table of Content

	1. Core
  2. Header
  3. Modal
  4. Button
  5. Form | Input
  6. Number Input
  7. Checkbox
  8. Radio Group
  9. Picker
  10. Slider
  11. Message
  12. Switch
  13. ToolTip
  14. Transfer
  15. DropDown Menu
  16. Tabs Panel
  17. Empty
  18. Side Bar
  19. Alert
  20. Spin
  21. Menu Tabs
  22. Collapse
  23. Popover
  24. Table

----------------------------------- */

// 1. Core
.ant-col {
  padding: 0 0.5rem;
  font-family: $font-main;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  &.ant-typography {
    font-family: $font-main;
    color: $color-grey;
  }
}

h1 {
  &.ant-typography {
    color: $color-grey;
    font: {
      size: 65px;
      weight: 500;
    }
  }

  &.medium {
    font-size: 35px !important;
  }
}

h2 {
  &.ant-typography {
    font: {
      size: 35px;
      weight: 400;
    }
  }
}

h3 {
  &.ant-typography {
    font: {
      size: 30px;
      weight: 400;
    }
  }
}

h4 {
  &.ant-typography {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-radius: 10px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
  }
}

p {
  &.ant-typography {
    color: $color-grey;
  }
}

.ant-typography {
  display: block;
  font-family: $font-main;
  line-height: 28px;
  color: $color-grey;
}

// 2. Button
.ant-btn-default {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  height: auto;
  color: $color-white;
  padding: 14px 35px;
  border-radius: 5px;
  font: {
    weight: 600;
    family: $font-main;
  }
  background: transparent;
  border: 1px solid $color-white;
  transform: rotateX(20deg);
  transition: all 0.4s;

  &:hover {
    color: $color-black !important;
    background: $color-white;
    border-color: transparent !important;
    transform: rotateX(0deg);

    svg {
      g path {
        fill: $color-black;
      }
    }
  }
}

.ant-btn-primary {
  font-family: $font-main;
  color: $color-white;
  background-color: $color-main;
  outline: 1px dashed $color-main !important;
  outline-offset: 2px;

  &:disabled {
    border: none;
    color: $disabled-color;
    background-color: $disabled-background;
    outline-color: $disabled-background !important;
  }

  &:not(:disabled):hover {
    opacity: 0.5;
    background-color: $color-main !important;
  }
}

.ant-btn-dashed {
  display: flex;
  align-items: center;
  gap: 7px;
  height: auto;
  color: $color-main;
  font: {
    size: 15px;
    family: $font-main;
  }
  border: none;
  padding: 7px 15px;
  background: $color-main-200;
  outline: 1px dashed $color-main-200 !important;
  outline-offset: 2px;

  &:hover {
    color: $color-main !important;
    opacity: 0.6;
  }

  &.cancel {
    color: #d94c48;
    background: rgba(217, 76, 72, 0.2);
    outline: 1px dashed rgba(217, 76, 72, 0.2) !important;

    &:hover {
      color: #d94c48 !important;
    }
  }

  &:disabled {
    color: rgba(0, 195, 154, 0.5);
    background: transparent;
  }
}

// 3. Form | Input
.ant-form {
  color: $color-grey;
  font-family: $font-main;
}

.ant-input {
  min-height: 40px;
  border: none;
  font-family: $font-main;
  color: $color-white;
  background: $background-grey-200;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 3px;
  transition: all 0.3s;

  &:hover {
    outline-color: $color-outline-input !important;
  }

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: $color-grey;
  }

  &.ant-input-status-error {
    outline: 1px dashed $error-border !important;
  }
}

.ant-form-item {
  .ant-form-item-label {
    margin: 0;
    padding: 0 0 8px;
    white-space: initial;
    text-align: start;

    label {
      padding: 0 0.5rem;
      color: $color-grey;

      &.ant-form-item-required {
        &:not(.ant-form-item-required-mark-optional) {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.ant-form-item-with-help {
  .ant-form-item-explain {
    color: #d94c48;
    margin-top: 3px;
    font: {
      size: 12px;
      family: $font-main;
    }
  }
}

.ant-form-item-row {
  flex-direction: column;
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
  border: none;
  background: $background-grey-200;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 3px;

  .ant-input {
    outline: none !important;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  &:hover {
    outline-color: $color-outline-input !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &::placeholder {
    color: $color-grey;
  }

  &.ant-input-affix-wrapper-status-error {
    outline: 1px dashed $error-border !important;
  }

  &.ant-input-password {
    .ant-input-suffix {
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 70%;
        top: 50%;
        left: -10px;
        background-color: $element-color;
        transform: translateY(-50%);
      }
    }
  }
}

// 4. Input Search
.ant-input-search {
  .ant-input-wrapper {
    border-radius: 6px;
    background: $background-grey-200;
    outline: 1px dashed $background-grey-200 !important;
    outline-offset: 3px;

    .ant-input {
      outline: none !important;
    }

    .ant-input-group-addon {
      inset-inline-start: -6px !important;
    }
  }

  .ant-input-search-button {
    padding: 10px;
    border-radius: 6px !important;
    outline: none !important;
    background-color: rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}

// 4. Select
.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    min-height: 40px;
    border: none;
    font-family: $font-main;
    color: $color-white;
    background: $background-grey-200;
    outline: 1px dashed $background-grey-200 !important;
    outline-offset: 3px;
    box-shadow: none !important;
    transition: all 0.3s;

    .ant-select-selection-search-input {
      min-height: 40px;
      font-family: $font-main;
    }

    .ant-select-selection-item {
      line-height: 40px;

      img {
        max-width: 18px;
        margin-right: 5px;
      }
    }

    &:hover {
      outline-color: $color-outline-input !important;
    }

    .ant-select-selection-placeholder {
      line-height: 40px;
      color: $color-grey;
    }
  }
}

.ant-select-single {
  height: 40px;

  &.ant-select-open {
    .ant-select-selection-item {
      color: $color-white;
    }
  }
}

.ant-select-dropdown {
  font-family: $font-main;
  margin: 5px 0;
  background: $background-color;
  outline: 1px dashed $background-color !important;
  outline-offset: 3px;

  .ant-select-item {
    color: $color-white;

    &:hover {
      background: $element-color;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $color-main !important;
    background: $background-grey-200;
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    gap: 7px;

    img {
      max-width: 18px;
    }
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    background: rgba(0, 0, 0, 0.5);
  }

  .anticon.anticon-close {
    color: #d94c48;
  }

  .ant-select-selection-item-content {
    line-height: 20px !important;
  }
}

// 5. Number Input
.ant-input-number {
  width: 100%;
  border: none;
  box-shadow: none;
  border-radius: 6px;
  background-color: $background-grey-200;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 3px;
  transition: outline 0.3s;

  .ant-input-number-input {
    height: 40px;
    color: $color-white;
    font-family: $font-main;

    &::placeholder {
      color: $color-grey;
    }
  }

  &.ant-input-number-disabled {
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;

    &:hover {
      outline-color: #383b40 !important;
    }
  }

  .ant-input-number-handler-wrap {
    height: 80%;
    top: 50%;
    background: $background-grey-200;
    transform: translateY(-50%);
  }

  .ant-input-number-handler {
    border-inline-start: none;

    &:hover {
      opacity: 0.5;
    }
  }

  .ant-input-number-handler-down {
    border-block-start: none;
  }

  &:first-child {
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    outline-color: $color-outline-input !important;
  }
}

.ant-input-number-group-wrapper {
  width: 100%;

  .ant-input-number-group {
    border-radius: 6px;
    outline: 1px dashed $background-grey-200 !important;
    outline-offset: 3px;
    transition: outline 0.3s;

    .ant-input-number {
      display: block;
      width: calc(100% - 3px);
      border: none;
      box-shadow: none;
      background-color: $background-grey-200;
      outline: none !important;

      .ant-input-number-input {
        height: 40px;
        color: $color-white;
        font-family: $font-main;

        &::placeholder {
          color: $color-grey;
        }
      }

      .ant-input-number-handler-wrap {
        height: 80%;
        top: 50%;
        background: $background-grey-200;
        transform: translateY(-50%);
      }

      .ant-input-number-handler {
        border-inline-start: none;

        &:hover {
          opacity: 0.5;
        }
      }

      .ant-input-number-handler-down {
        border-block-start: none;
      }

      &:first-child {
        border-start-end-radius: 6px;
        border-end-end-radius: 6px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .ant-input-number-group-addon {
      color: $color-white;
      border: none;
      background-color: $background-grey-200;

      &:last-child {
        border-start-start-radius: 6px;
        border-end-start-radius: 6px;
      }

      .ant-select {
        &.ant-select-single {
          .ant-select-selector {
            height: 40px;
            border: none;
            box-shadow: none;
            color: $color-white;
            font-family: $font-main;
            outline: none !important;

            .ant-select-selection-item {
              line-height: 39px;
            }
          }

          &.ant-select-show-arrow {
            .ant-select-selection-item {
              position: relative;
              top: 1px;
              padding-inline-end: 22px;
            }
          }

          &.ant-select-open {
            .ant-select-selection-item {
              color: $color-main;
              box-shadow: none;
            }
          }
        }
      }
    }

    &:hover {
      outline-color: $color-outline-input !important;
    }
  }

  &.ant-input-number-group-wrapper-status-error {
    .ant-input-number-group {
      outline: 1px dashed $error-border !important;
    }
  }
}

// 6. Checkbox
.ant-checkbox-wrapper {
  color: $color-grey;
  font-family: $font-main;

  &:hover {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $color-main !important;
      }
    }
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border: none;
    border-radius: 4px;
    background-color: $background-grey-200;
    outline: 1px dashed $background-grey-200 !important;
    outline-offset: 3px;

    &::after {
      inset-inline-start: 24%;
      border: 2px solid $background-color;
      border-top: 0;
      border-left: 0;
    }
  }

  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $color-main;
    }

    &::after {
      display: none;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      outline-color: $color-outline-input !important;
    }
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    &:after {
      border: none;
      background-color: $color-main;
      inset-inline-start: 50% !important;
    }
  }
}

.icon-connect {
  label {
    svg {
      position: relative;
      top: 7px;
    }

    &.ant-radio-button-wrapper-checked {
      svg {
        path {
          fill: $color-black;
        }
      }

      img {
        filter: contrast(1);
      }
    }

    &:nth-child(1) {
      svg {
        path {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            opacity: 0.2;
          }
        }
      }
    }

    &:nth-child(2) {
      svg {
        path {
          &:nth-child(3),
          &:nth-child(4) {
            opacity: 0.2;
          }
        }
      }
    }

    &:nth-child(3) {
      svg {
        path {
          &:nth-child(4) {
            opacity: 0.2;
          }
        }
      }
    }

    img {
      position: relative;
      top: -1px;
      width: 16px;
      height: 16px;
      filter: contrast(0.01);
    }
  }
}

// 7. Radio Group
.ant-radio-group {
  border-radius: 6px;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 3px;

  .ant-radio-button-wrapper {
    border: none;
    margin: 5px;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    color: $color-grey;
    font: {
      weight: 500;
      family: $font-main;
    }
    border-radius: 6px;
    background: $background-grey-200;

    &::before {
      display: none;
    }

    &.ant-radio-button-wrapper-checked {
      color: $element-color;
      background: $color-main;
    }

    &.ant-radio-button-wrapper-disabled {
      opacity: 0.5;
    }
  }
}

// 8. Modal
.ant-modal {
  color: $color-grey;
  font-family: $font-main;

  .ant-modal-content {
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: $shadow;
    background-color: $element-color;
  }

  .ant-modal-header {
    position: relative;
    border-radius: 0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    background-color: unset;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left: 50%;
      bottom: 0;
      border: 1px dashed $background-color;
      transform: translateX(-50%);
    }

    .ant-modal-title {
      color: $color-grey;
      font: {
        weight: 400;
        size: 20px;
      }
    }
  }

  .ant-modal-close {
    width: 24px;
    height: 24px;
    top: 25px;
    inset-inline-end: 22px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

// 9. Picker
.ant-picker {
  width: 100%;
  min-height: 40px;
  border: none;
  color: $color-white;
  background: $background-grey-200;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 3px;
  transition: all 0.3s;
  .ant-picker-input {
    font-family: $font-main;

    input {
      color: $color-white;

      &::placeholder {
        color: $color-grey;
      }
    }

    input[disabled] {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .ant-picker-clear {
    background: $background-grey-200;
  }

  &:not(.ant-picker-disabled) {
    &.ant-picker-status-error {
      background-color: $background-grey-200 !important;
      outline: 1px dashed $error-border !important;

      &:hover {
        background: $background-grey-200 !important;
      }
    }
  }
}

.ant-picker-focused {
  box-shadow: none;
  outline-color: $color-outline-input !important;
}

.ant-picker-dropdown {
  font-family: $font-main;

  .ant-picker-panel-container {
    margin: 5px 0;
    background: $background-color;
    outline: 1px dashed $background-color !important;
    outline-offset: 3px;
  }

  .ant-picker-header {
    color: $color-white;

    .ant-picker-header-view button {
      &:hover {
        color: $color-main;
      }
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ant-picker-content {
    th {
      color: $color-white;
    }

    .ant-picker-cell-in-view {
      color: $color-white !important;

      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: $color-main;
        }
      }

      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          &::before {
            border: none;
            outline: 1px dashed $color-main !important;
            outline-offset: 2px;
          }
        }
      }
    }

    .ant-picker-cell {
      font-weight: 600;
      color: #696969;

      &:hover:not(.ant-picker-cell-selected):not(
          .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
          .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background: $element-color;
      }
    }
  }

  .ant-picker-time-panel-column {
    li {
      &.ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
          color: $color-main !important;
          background: $background-grey-200;
        }
      }

      &.ant-picker-time-panel-cell {
        .ant-picker-time-panel-cell-inner {
          color: $color-white;

          &:hover {
            background: $element-color;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: $element-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-main;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-main;
    }
  }

  .ant-picker-ranges {
    gap: 15px;
    line-height: 40px;
    justify-content: center;

    .ant-picker-ok {
      margin-inline-start: unset;
    }
  }

  .ant-picker-panel {
    .ant-picker-footer {
      margin: 5px;
      border-radius: 6px;
      min-width: calc(100% - 10px);
      background: $element-color;
      border-top: none;
    }
  }

  .ant-picker-today-btn {
    padding: 1px 7px;
    border-radius: 4px;
    color: $color-white !important;
    font-family: $font-main;
    outline: 1px dashed $color-main !important;
    outline-offset: 3px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}

// 10. Slider
.ant-slider {
  margin: 15px 5px;

  .ant-slider-rail {
    outline: 1px dashed $background-grey-200 !important;
    outline-offset: 10px;
    background-color: $background-color;
  }

  .ant-slider-track {
    background-color: $color-main;
  }

  .ant-slider-handle {
    width: 8px;
    height: 8px;

    &::after {
      width: 8px;
      height: 8px;
      background-color: $background-color;
      box-shadow: 0 0 0 2px $color-main;
    }

    &:hover::after,
    &:focus::after {
      width: 8px;
      height: 8px;
      inset-inline-start: 0;
      inset-block-start: 0;
      box-shadow: 0 0 0 4px $color-main;
    }
  }

  &:hover {
    .ant-slider-track {
      background-color: $color-main;
    }

    .ant-slider-handle {
      &::after {
        box-shadow: 0 0 0 4px $color-main;
      }
    }
  }
}

.ant-slider-horizontal {
  .ant-slider-handle {
    inset-block-start: 1.7px;
  }
}

// 11. Message
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      color: $color-white;
      font-family: $font-main;
      background: $background-grey-200;
      outline: 1px dashed $background-grey-200 !important;
      outline-offset: 3px;

      .ant-message-custom-content {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

// 12. Switch
.ant-switch {
  border: none;
  box-shadow: none;
  background: $background-color;
  outline: 1px dashed $background-grey-200 !important;
  outline-offset: 4px;

  &.ant-switch-checked {
    background: $color-main;

    .ant-switch-inner {
      .ant-switch-inner-checked {
        margin-top: 3px;
      }
    }

    &:hover:not(.ant-switch-disabled) {
      background: $color-main;
    }
  }

  .ant-switch-handle {
    top: 4px;
    inset-inline-start: 4px;
    width: 14px;
    height: 14px;
  }

  .ant-switch-inner {
    .ant-switch-inner-unchecked {
      margin-top: -19px;
    }
  }

  &:hover:not(.ant-switch-disabled) {
    outline-color: $color-outline-input !important;
  }
}

// 13. ToolTip
.ant-tooltip {
  font-family: $font-main;
}

// 14. Transfer
.ant-transfer {
  margin-bottom: 40px;

  .ant-transfer-list {
    height: 300px;
    outline: 1px dashed #383b40 !important;
    outline-offset: 3px;
    border: 2px solid #151515;

    &:first-child {
      width: 50%;
    }

    &:last-child {
      flex: 1 1 50%;
    }
  }

  .ant-transfer-list-header {
    color: $color-white;
    height: 55px;
    background: $background-color;

    > *:not(:last-child) {
      margin-inline-end: 10px;
    }
  }

  .ant-transfer-list-header-dropdown {
    transform: translateY(0);
  }

  .ant-transfer-list-header-selected {
    display: none;
  }

  .ant-transfer-list-content {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: $background-grey-200;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-main;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color-main;
    }
  }

  .ant-transfer-list-content-item {
    margin: 5px;
    padding: 10px 12px;
    border-radius: 5px;
  }

  .ant-transfer-list-content-item-text {
    color: $color-grey;
  }

  .ant-transfer-list-content-item-checked {
    background-color: rgba(0, 195, 154, 0.1);

    &:hover {
      background-color: rgba(0, 195, 154, 0.1) !important;
    }
  }

  .ant-transfer-operation {
    gap: 5px;

    .ant-btn {
      outline: none !important;
    }
  }
}

// 15. DropDown Menu
.ant-dropdown {
  .ant-dropdown-menu {
    background: $element-color;
    box-shadow: $shadow;

    .ant-dropdown-menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px;
      transition: all 0.3s;
      cursor: pointer;
      line-height: 2;
      font-family: $font-main;
      font-weight: 600;
      color: $color-grey;
      text-align: left;

      &:last-of-type {
        border: none;
      }
    }

    .ant-dropdown-menu-item-active {
      color: $color-main;
      background-color: $color-main-100 !important;

      svg {
        g {
          path,
          circle {
            fill: $color-main;
          }
        }
      }
    }
  }

  .ant-table-filter-dropdown {
    background-color: transparent;

    .filter-user-dropdown {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 15px;
      background-color: #222325;
      box-shadow: $shadow;

      .ant-col {
        padding: 0;
      }

      .ant-input {
        outline: none !important;
      }

      .ant-radio-group {
        width: 100%;
        padding: 7px;
        outline: none !important;
        background: $background-color;

        .ant-radio-wrapper {
          color: $color-grey;
          transition: opacity 0.3s;

          .ant-radio-inner {
            border: none;
            border-radius: 4px;
            background-color: #383b40;

            &::after {
              background-color: $color-main;
            }
          }

          &.ant-radio-wrapper-checked {
            color: $color-main;
          }

          .ant-radio-checked {
            &::after {
              display: none;
            }
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        background: #36393e;
        padding: 7px;
        border-radius: 5px;

        .ant-btn {
          height: auto;
          padding: 5px 10px;
          outline: none !important;

          &.ant-btn-dashed {
            color: $color-grey;
            font-weight: 700;
            background-color: transparent;

            &:hover {
              color: $color-red !important;
            }
          }

          &.ant-btn-primary {
            font-weight: 700;
            color: $color-main !important;
            background: rgba(0, 195, 154, 0.2) !important;

            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-dkbvqv).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  padding: 10px;
}

// 16. Tabs Panel
.ant-tabs {
  &.ant-tabs-card {
    .ant-tabs-nav {
      padding: 10px;
      border-radius: 10px;
      background-color: $background-color;

      &::before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        overflow-x: auto;
      }

      .ant-tabs-nav-list {
        width: 100%;
        gap: 10px;
        padding-bottom: 5px;
        overflow: auto;

        .ant-tabs-tab {
          width: 100%;
          justify-content: center;
          color: $color-grey;
          font: {
            size: 16px;
            family: $font-main;
          }
          padding: 0;
          border: none;
          border-radius: 5px;
          background: $element-color;

          .ant-tabs-tab-btn {
            display: flex;
            align-items: center;
            gap: 7px;
            padding: 15px;
          }

          &.ant-tabs-tab-active {
            color: $color-main;
            background-color: $color-main-200;

            .ant-tabs-tab-btn {
              color: $color-main;

              svg {
                g {
                  path,
                  circle {
                    fill: $color-main;
                  }
                }
              }
            }
          }
        }
      }

      .ant-tabs-nav-operations {
        display: none;
      }
    }
  }

  &.big-size {
    &.ant-tabs-card {
      .ant-tabs-nav {
        margin-bottom: 50px;

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 0;
            font-size: 18px;
          }
        }
      }
    }
  }
}

// 17. Empty
.ant-empty {
  .ant-empty-image {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      top: 50%;
      left: 50%;
      background-size: cover;
      background-image: url(../../img/icon/folder-times.svg);
      transform: translate(-50%, -50%);
    }

    svg {
      display: none;
    }
  }

  .ant-empty-description {
    font-family: $font-main;
    color: $color-grey;
  }

  &.big {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-empty-image {
      height: 70px;

      &::after {
        width: 60px;
        height: 60px;
      }
    }
  }
}

// 18. Side Bar
.ant-menu {
  background-color: transparent;

  &.ant-menu-dark {
    .ant-menu-item-selected {
      background-color: rgba(0, 195, 154, 0.1);
    }
  }
}

.ant-menu-inline-collapsed {
  position: fixed;
  width: 58px;
  top: 50%;
  transform: translateY(-50%);

  .ant-menu-item {
    height: 50px;
    line-height: 57px;
    padding-inline: calc(50% - 16px);

    &.main {
      &::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background-color: $color-main;
        animation: flicker 1.5s infinite linear;
      }
    }
  }
}

// 19. Alert
.ant-alert {
  font: {
    size: 12px;
    family: $font-main;
  }
  border: none;
  margin: 0 0.5rem;

  .ant-alert-message {
    text-align: left;
  }

  &.ant-alert-success {
    color: #25ca7f;
    background: rgba(37, 202, 127, 0.15);
  }

  &.ant-alert-info {
    color: #00a3e1;
    background: rgba(0, 163, 225, 0.15);
  }

  &.ant-alert-warning {
    color: #bcb727;
    background: rgba(188, 183, 39, 0.15);
  }

  &.ant-alert-error {
    color: #d94c48;
    background: rgba(217, 76, 72, 0.15);
  }

  .ant-alert-icon {
    margin-top: 1px;
  }
}

// 20. Spin
.ant-spin {
  position: relative;
  top: 50%;

  .ant-spin-dot-item {
    background-color: $color-main;
  }

  .ant-spin-dot {
    width: auto;
    height: auto;
    animation: 1s spin infinite linear;
  }
}

.ant-spin-nested-loading {
  .ant-spin-container {
    &::after {
      left: 5px;
      width: calc(100% - 10px);
      height: calc(100% + 10px);
      background: #000000;
      border-radius: 8px;
    }
  }

  div {
    .ant-spin {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// 21. Menu Tabs
.ant-menu-light {
  &.ant-menu-horizontal {
    padding: 10px;
    border-radius: 10px;
    background-color: #202124;
    margin: 0 0.25rem 30px;

    .ant-menu-item {
      display: flex;
      align-items: center;
      color: $color-grey;
      font-size: 16px;
      font-family: "Jura", sans-serif;
      border: none;
      border-radius: 5px;
      margin-right: 10px;
      transition: all 0s;
      background: $background-color;

      .ant-menu-title-content {
        a {
          transition: none;
        }
      }

      &:hover {
        color: $color-main !important;
        background: $background-color;

        svg {
          transition: all 0.3s;

          g {
            path {
              fill: $color-main;
            }
          }
        }

        &::after {
          border-bottom-width: 1px;
          border-color: $color-main;
        }
      }

      &::after {
        border-bottom: 1px dashed transparent;
      }
    }

    .ant-menu-item-selected {
      color: $color-main;

      svg {
        transition: all 0.3s;

        g {
          path {
            fill: $color-main;
          }
        }
      }

      &::after {
        border-bottom-width: 1px;
        border-color: $color-main;
      }
    }
  }
}

// 22. Collapse
.ant-collapse {
  position: relative;
  border: none;
  padding: 0 1rem;
  border-radius: 0;
  background: transparent;
  z-index: 1;

  .ant-collapse-item {
    border: none;

    .ant-collapse-header {
      position: relative;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid #222325;

      .ant-collapse-header-text {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #ababab;
        text-transform: uppercase;
        font: {
          size: 22px;
          family: $font-main;
          weight: 600;
        }
        text-align: left;
        background: linear-gradient(179.14deg, #ffffff 7.77%, #444444 120.01%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .ant-collapse-arrow {
        transition: all 0.3s;
      }
    }

    .ant-collapse-content {
      color: $color-grey;
      font-family: $font-main;
      border: none;
      background: transparent;

      .ant-collapse-content-box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 30px;
        padding: 0;
        background: transparent;
      }
    }
  }

  &.border {
    margin: 0 0.5rem;
    border-radius: 10px;
    border-top: 1px dashed #383b40;
    border-left: 1px dashed #383b40;
    padding: 15px;

    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          margin-bottom: 0;
        }
      }
    }
  }

  &.form-collapse {
    .ant-collapse-item {
      border-radius: 10px;
      background: $element-color;

      .ant-collapse-header {
        border-bottom: none;
        border-radius: 10px;

        .ant-collapse-header-text {
          font-size: 16px;
          font-weight: 700;
          text-transform: none;
        }

        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          height: calc(100% + 2px);
          width: calc(100% + 2px);
          border-radius: 10px;
          background: linear-gradient(161deg, #2c2c2c 20%, transparent 65%);
          z-index: -1;
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: calc(100% + 25px);
            top: -25px;
            left: -1px;
            border-end-start-radius: 10px;
            border-left: 1px solid #2c2c2c;
          }
        }
      }
    }
  }
}

// 23. Popover
.ant-popover {
  .ant-popover-inner {
    background-color: #313338;

    .pre-img {
      border-radius: 5px;
      box-shadow: $shadow;
      transition: all 0.3s;

      img {
        max-width: 180px;
        border-radius: 5px;
      }
    }
  }

  .ant-popover-arrow {
    &::before {
      background: #313338;
    }
  }
}

// 24. Table
.ant-table-wrapper {
  border-radius: 10px;
  border-top: 1px dashed $background-grey-200;
  border-left: 1px dashed $background-grey-200;

  .ant-table {
    margin: 4px;
    font-family: $font-main;
    background: transparent;
    border-radius: 10px;

    .ant-table-filter-trigger {
      color: #ababab;
    }

    .ant-table-thead {
      tr {
        th {
          color: $color-grey;
          background: $background-color;
          border: none;

          &::before {
            background-color: $background-grey-200 !important;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          color: $color-grey;
          background: $element-color;
          border: none;

          &:first-child {
            border-radius: 10px 0px 0px 10px;
          }

          &:last-child {
            border-radius: 0px 10px 10px 0px;
          }
        }

        .main,
        .edit,
        .delete {
          display: flex;
          align-items: center;
          justify-content: center;

          a,
          .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px;
            border-radius: 8px;
            background: rgba(0, 163, 225, 0.1);
          }
        }

        .main {
          .ant-btn {
            background: rgba(0, 195, 154, 0.1);
          }
        }

        .delete {
          .ant-btn {
            background: rgba(217, 76, 72, 0.1);
          }
        }
      }

      .ant-table-row {
        .ant-table-cell-row-hover {
          background: rgba(34, 35, 37, 0.3);
        }
      }
    }
  }

  table {
    padding: 0 7px;
    border-radius: 10px;
    border-spacing: 0 10px;
  }
}

// 25. Pagination
.ant-pagination {
  .ant-pagination-item {
    font-family: $font-main;
    background-color: $element-color;

    a {
      transition: all 0.3s;
      color: $color-grey;
    }

    &:hover {
      background-color: $element-color !important;
      outline: 1px dashed $element-color !important;
      outline-offset: 2px;

      a {
        opacity: 0.5;
      }
    }
  }

  .ant-pagination-item-active {
    border: none;
    background-color: rgba(0, 195, 154, 0.1);
    outline: 1px dashed rgba(0, 195, 154, 0.3) !important;
    outline-offset: 2px;

    a {
      color: $color-main;
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    background-color: transparent;

    .ant-pagination-item-container {
      .ant-pagination-item-ellipsis {
        font-family: $font-main;
        color: $color-grey;
      }

      .ant-pagination-item-link-icon {
        font-family: $font-main;
        color: $color-main;
      }
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      color: $color-grey;
      background-color: $element-color !important;
      outline-color: $element-color !important;

      button {
        border: none;
      }
    }

    &:hover {
      .ant-pagination-item-link {
        background-color: $element-color !important;
        outline: 1px dashed $element-color !important;
        outline-offset: 2px;

        span {
          opacity: 0.5;
        }
      }
    }

    &.ant-pagination-disabled {
      .ant-pagination-item-link {
        opacity: 0.5;
      }
    }
  }
}

// 26. Breadcrumb
.ant-breadcrumb {
  color: $color-grey;
  font-family: $font-main;
  overflow-x: auto;

  ol {
    flex-wrap: nowrap;
    gap: 15px;

    li {
      a {
        &.ant-breadcrumb-link {
          color: $color-grey;
          transition: all 0.3s;

          svg {
            g path {
              transition: all 0.3s;
            }
          }

          &:hover {
            color: $color-main;

            svg {
              g path {
                fill: $color-main;
              }
            }
          }
        }
      }

      .ant-breadcrumb-link {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        color: #787878;
        padding: 0 5px;
        white-space: pre;

        .ant-dropdown-trigger {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 5px;
          color: $color-grey;
          transition: all 0.3s;

          svg {
            g path {
              transition: all 0.3s;
            }
          }

          &:hover {
            color: $color-main;

            svg {
              g path {
                fill: $color-main;
              }
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 60%;
          top: 50%;
          right: -6px;
          border-right: 1px dashed #ababab;
          transform: translateY(-50%) rotate(14deg);
        }
      }

      &:last-child {
        color: $color-grey;

        .ant-breadcrumb-link {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
